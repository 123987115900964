function initFileUploader() {
    $('.input-fileupload').each(function(index, node){
        var $box = $(node).parents(".file-upload-box");
        var $progress = $box.find(".progress-bar");

        $(node).fileupload({
            dataType: 'json',
            done: function (e, data) {
                var $filesJsonInput = $box.find("[name=filesJson]");
                var filesJson = $filesJsonInput.val();

                if(filesJson == "") filesJson = {files:[]};
                else filesJson = jQuery.parseJSON(filesJson);

                $.each(data.result.files, function (index, file) {
                    if(file.error != undefined) {
                        return;
                    }

                    var list = $box.find(".list");
                    var img = $('<img/>').attr('src', file.thumbnailUrl);
                    img.attr('title','Загрузка файлов');
                    var par = $('<p/>');

                    par.click(function(){
                        var jsStr = $box.find("[name=filesJson]").val();
                        var jsObj = jQuery.parseJSON(jsStr);

                        for(var i = 0; i < jsObj.files.length; i++) {
                            if(jsObj.files[i].url == file.url)
                                jsObj.files.splice(i, 1);

                        }

                        $box.find("[name=filesJson]").val(JSON.stringify(jsObj));
                        $(this).remove();
                    });

                    img.appendTo(par);
                    par.appendTo(list);
                    filesJson.files.push(file);
                });

                $filesJsonInput.val(JSON.stringify(filesJson));
                $(".progress").addClass('hidden');
            },
            progressall: function (e, data) {
                $(".progress").removeClass('hidden');
                var progress = parseInt(data.loaded / data.total * 100, 10);
                $progress.css('width', progress + '%');
                $progress.find("span").text(progress + '%');
            }
        });
    });

    $(".file-upload-box .list p").click(function() {
        var $box = $(this).parents(".file-upload-box");
        var jsStr = $box.find("[name=filesJson]").val();
        var jsObj = jQuery.parseJSON(jsStr);
        var img = $(this).find("img");

        for(var i = 0; i < jsObj.files.length; i++) {
            if(jsObj.files[i].thumbnailUrl == $(img).attr("src"))
                jsObj.files.splice(i, 1);
        }

        $box.find("[name=filesJson]").val(JSON.stringify(jsObj));
        $(this).remove();
    });
}

$(function() {
    new Swiper('.swiper-container', {
        speed: 400,
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    $(".wys").editable({
        inlineMode: false,
        editorClass: "textual-content",
        imageUploadURL: '/upload_image.php',
        imageUploadParams: {id: "my_editor"}
    });
    $("#gallery").justifiedGallery({captions:false});
    $('.swipebox').swipebox({useSVG : false});

    initFileUploader();

    $('a[href^=#]').on('click', function(event) {
        if (this.hash !== "") {
            event.preventDefault();

            var hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {
                window.location.hash = hash;
            });
        }
    });

    $('.form-userphoto input[type=file]').on('change', function() {
        $('.form-userphoto').submit();
    })
});

// Магазин
$(function () {
    var $score = $('.bucket-link-score');

    $('.bucket-add').on('click', function(e) {
        e.preventDefault();
        var $button = $(this);

        $.post('/shop/add', $button.data())
            .success(function (resp) {
                if (resp.count) {
                    $score.html(resp.count).removeClass('hidden');
                } else {
                    $score.addClass('hidden');
                }
            })
            .fail(function() {
                alert('Ошибка, проверьте подключение');
            });

        setTimeout(function() {
            $button.html('В корзину')
        }, 1000);

        $button.html('Добавлено!');
    });

    function updateAmount() {
        $.post("/shop/update", $('.page form').serializeArray())
            .success(function(resp) {
                $('.order-amount').html(resp.amount);
            });
    }

    $('.score-input').on('input', function() {
        if (this.value <= 0) {
            this.value = '1';
        }

        updateAmount();
    });

    $('.bucket-remove').on('click', function(e) {
        e.preventDefault();

        var $button = $(this);
        $button.parents('tr').remove();

        $.post($button.prop('href'))
            .success(function (resp) {
                if (!resp.count) {
                    window.location.reload();
                }

                $('.order-amount').html(resp.amount);
            });
    });

    // Форма заказа
    var $status = $('.form-order-edit-status');
    var bb = {
        1: {
            button: 'оплачено',
            reject: true,
            next: 3
        }, // Новый
        3: {
            button: 'отправили посылку',
            track: true,
            next: 4
        }, // Оплаченный
        4: {
            button: 'заказ получен',
            track: true,
            next: 5
        }, // Отправлен
        5: {track: true}, // Получен
        2: {track: true}, // Отменен
    };

    if ($status.length) {
        var status = parseInt($status.val(), 10);
        var sd = bb[status];
        var $cn = $('<div class="form-group form-order-action"><label>' + $status.data('text') + '</label></div>');
        var $form = $('.form-order-edit');
        var $track = $('#shoporderedit_track').parents('.form-group');

        if (sd.button) {
            $('<button type="submit" class="btn btn-warning">' + sd.button + '</button>')
                .on('click', function (e) {
                    e.preventDefault();

                    if (sd.next !== 3 || confirm('Вы уверены что хотите отметить заказ оплаченым? После его нельзя будет отменить')) {
                        $status.val(sd.next);
                        $form.submit();
                    }
                })
                .appendTo($cn);
        }

        if (sd.reject) {
            $('<button type="submit" class="btn btn-danger">отменить заказ</button>')
                .on('click', function (e) {
                    e.preventDefault();

                    if (confirm('Вы уверены что хотите отменить заказ?')) {
                        $status.val(2);
                        $form.submit();
                    }
                })
                .appendTo($cn);
        }

        if (!sd.track) {
            $track.addClass('hidden');
        }

        $status.after($cn);
    }
});
